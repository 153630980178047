import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const ArrowContainer = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  background-color: rgba(240, 240, 240, 0.2);
  border-radius: 50px;
  padding: 10px 12px 9px;
  color: darkgrey;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  opacity: ${props => props.visible ? 1: 0};
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  -ms-transition: visibility 0s, opacity 0.5s linear;
  cursor: pointer;
  @media (max-width: 480px) {
    right: 20px;
    bottom: 20px;
  }
`

const Arrow = () => {

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible(currentScrollPos > 50 );
    // setPrevScrollPos(currentScrollPos);
  }

  const handleClick = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  return(
    <ArrowContainer visible={visible} onClick={handleClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
           stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
           className="feather feather-chevron-up">
        <path d="M18 15l-6-6-6 6"/>
      </svg>
    </ArrowContainer>
  )
}

export default Arrow