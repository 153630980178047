import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  width: 100%;
  height: 44vh;
  background: url(${props => props.image}) no-repeat ${props => props.position};
  background-size: cover;
`

const Image = ({ image, position }) => {
  return (
      <StyledDiv image={image} position={position} />
  )
}

export default Image