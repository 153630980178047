import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-scroll'

const LinkContainer = styled.div`
  display: flex;
  font-family: Raleway, serif;
  
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`

const LinkItem = styled.span`
  text-decoration: none;
  cursor: pointer;
  margin: 0 15px;
  font-size: 1.2rem;
  color: ${props => props.visible === false ? 'white' : 'black'}
`

const LinkBrot = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${props => props.visible === false ? 'white' : 'black'}
`

const Menu = ({visible, offset}) => {
  return (
    <LinkContainer>
      <LinkItem visible={visible} ><Link to="location" smooth={true} offset={-offset}>Anreise</Link></LinkItem>
      <LinkItem visible={visible} ><Link to="party" smooth={true} offset={-offset}>Feier</Link></LinkItem>
      <LinkItem visible={visible} ><Link to="contact" smooth={true} offset={-offset}>Details</Link></LinkItem>
      <LinkItem visible={visible} ><LinkBrot visible={visible} href={"/hausbrot-rezept"} >Hausbrot Rezept</LinkBrot></LinkItem>
      <LinkItem visible={visible} ><LinkBrot visible={visible} href={"/fotos"} >Fotos</LinkBrot></LinkItem>
    </LinkContainer>
  )}

export default Menu