import React, { createRef, useEffect, useState } from 'react'
import styled from "styled-components"
import Menu from './menu'

const Container = styled.div`
  @font-face {
    font-family: "AndelliaDavilton";
    src: url("../fonts/AndelliaDavilton.otf");
  }
  @media (max-width: 480px) {
    font-size: 3rem ;
  }
  position: fixed;
  top: 0;
  width: 100%;
  //height: 8vh;
  min-height: 8vh;
  background-color: rgba(255,255,255, ${props => props.visible === false ? 0.1 : 1});
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  z-index: 9999;
`

export default function Header() {

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const header = createRef()

  useEffect(() => {
   setHeaderHeight(header.current.clientHeight)
  },[header])

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible(currentScrollPos > (window.innerHeight - headerHeight) );
    setPrevScrollPos(currentScrollPos);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll])

  return (
    <Container ref={header} visible={visible}>
      <Menu offset={headerHeight} visible={visible}/>
    </Container>
  )
}