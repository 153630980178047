import React from "react"
import styled from "styled-components"

const Container = styled.div`
  margin: 4vh 18vw;
  //height: 50%;
  //height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: Raleway, serif;

  @media (max-width: 480px) {
    margin: 4vh 6vw;
  }
`

const TextBox = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  )
}

export default TextBox