import React from "react"
import styled from "styled-components"
import Image from './image'
import TextBox from './textbox'

const SectionContainer = styled.div`
  width: 100%;
  //border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  //height: 88vh;
`

const Section = ({ image, position, children, id }) => {
  return (
    <SectionContainer id={id}>
      {image && <Image image={image} position={position} />}
      <TextBox>
        {children}
      </TextBox>
    </SectionContainer>
  )
}
export default Section