import React from 'react'
import styled, { createGlobalStyle } from "styled-components"
import Header from '../components/header'
import Section from '../components/section'
import Arrow from '../components/arrow'
import Polaroid from '../components/polaroid'
import image1 from '../images/_200x200.jpg'
import image2 from '../images/ossiachersee-insider-stiftsschmiede-ossiach.jpeg'
import schifffahrt from '../images/schifffahrt.jpeg'
import andiundtoni from '../images/Toni&Andi-9.jpg'
import toniUndAndi from '../images/Toni&Andi-3-blub.jpg'
import * as fontFiles from '../fonts/fonts'
import CinzelR from '../fonts/Cinzel-VariableFont_wght.ttf'

// import image3 from '../images/austrodesign-luft9721.jpg'
// import schiff from '../images/schiff.jpg'
// import toniUndAndi from '../images/Toni&Andi-13.jpg'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Cinzel;
    src: url(${fontFiles.Cinzel}) format("ttf"), url(${fontFiles.Cinzel2}) format('woff'), url(url(${fontFiles.Cinzel3})) format('woff2');
  }
  @font-face {
    font-family: AndelliaDavilton;
    src: url(${fontFiles.AndelliaDavilton}) format("opentype");
  }
  @font-face {
    font-family: Raleway;
    src: url(${fontFiles.RalewayLight}) format("truetype");
  }
  body {
    font-family: Raleway, sans-serif;
    font-weight: lighter;
    font-size: 0.9rem;
    margin: 0;
    padding: 0;
    color: black;
    background-color: white;
  }
  h3 {
    margin-block-end: 0em;
    margin-block-start: 0;
    font-family: AndelliaDavilton, sans-serif;
    font-weight: normal;
    font-size: 3rem;
  }
  h4 {
    margin-block-end: 0em;
    font-weight: normal;
    font-size: 1rem;
  }
  .subtitle {
    font-family: Cinzel, serif;
    font-weight: normal;
  }
`

const Bold = styled.span`
font-weight:normal;
`

const ContentContainer = styled.div`
  //margin-top: 16vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const FotoContainer = styled.div`
  width: 100%;
  height: 100vh;
`

const Foto = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.image}) no-repeat ${props => props.position};
  background-size: cover;
`

const ColumnsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const Column = styled.div`
  margin: 0 20px;
`

const UnstyledLink = styled.a`
  text-decoration: none;
  color: black;
`

const ImageOverlay = styled.div`
  font-family: AndelliaDavilton, sans-serif;
  font-weight: normal !important;
  text-align: center;
  position: absolute;
  bottom: 6vh;
  font-size: 6rem ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  width: 100%;

  @media(min-width: 480px) {
    bottom: 12vh;
  }
  
  .subtitle {
    font-family: Cinzel, serif;
    font-size: 2.2rem;
    @media (max-width: 480px) {
      font-size: 1.7rem;
    }
  }
  
  #title-web {
    display: none;
    @media (min-width: 480px) {
      display: block;
    }
  }
  
  #title-mobile {
    display: block;
    font-size: 5rem;
    @media (min-width: 480px) {
      display: none;
    }

    &.small {
      font-size: 4rem;
      margin: -20px 0;
    }
  }
`

const Subtitle = styled.div`
  font-family: Cinzel, serif;
  font-size: 2.2rem;
  @media (max-width: 480px) {
    font-size: 1.7rem;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default () => {
  return (
  <>
    <title>Mr and Mrs Dandyman</title>
    <GlobalStyle />
    <Header />
    <ContentContainer>
      <ImageOverlay>
        <div id='title-web'>Antonia & Andreas</div>
        <div id='title-mobile'>Antonia</div>
        <div id='title-mobile' className='small'>&</div>
        <div id='title-mobile'>Andreas</div>
        <Subtitle className={'subtitle'} >03. 09. 2021</Subtitle>
      </ImageOverlay>
      <FotoContainer>
        <Foto image={toniUndAndi} position={"top"}/>
      </FotoContainer>
      <Section>
        <p>Wir freuen uns, dass ihr hergefunden habt!</p>
        <p>Alle notwendigen Informationen zu Anreise, Feier und Details sind hier für euch zusammengefasst.
          <br />Falls doch noch etwas unklar ist, könnt ihr uns natürlich auch direkt kontaktieren.
        </p>
        <p>Wir freuen uns auf jeden Fall schon sehr und können es kaum erwarten mit euch unsere Hochzeit zu feiern!</p>
        <p></p>
        <h3>Mr. und Mrs. Dandyman in Spe</h3>
        <p></p>
      </Section>
      <Section id='location' image={image1} position={"right"}>
        <h3>Anreise</h3>
        <h4>Location</h4>
        <p>Der Ort Ossiach, im Herzen Kärntens gelegen, ist über die A10 Abfahrt „Ossiachersee“ erreichbar.
          <br/>Von dort sind es noch 15km entlang des Ostufers bis nach Ossiach.
          <br/>Die Kirche und das Restaurant sind nur ein paar Gehminuten voneinander entfernt und direkt am See gelegen.
        </p>
        <ColumnsContainer>
          <Column>
            <Bold>Kirche</Bold>
            <br/>
            <br/>Stift Ossiach
            <br/>Ossiach 1
          </Column>
          <Column>
            <Bold>Restaurant</Bold>
            <br/>
            <br/>Stiftsschmiede Ossiach
            <br/>Ossiach 4
          </Column>
        </ColumnsContainer>
        <h4>Hotel</h4>
        <p>Für die Feier haben wir bereits von Donnerstag bis Samstag in Ossiach Zimmer geblockt, sodass ihr die Zeit in vollen Zügen genießen könnt.</p>
        <ColumnsContainer>
          <Column>
            <Bold>Hotel Krappinger</Bold>
            <br/>
            <br/>DZ - 75€ / Tag p.P. (ab 2 Nächte 65€)
            <br/><UnstyledLink href="https://krappinger.com">krappinger.com</UnstyledLink>
            <br/>
            <br/>reserviert bis Ende April
          </Column>
          <Column>
            <Bold>Hotel Post</Bold>
            <br/>
            <br/>DZ - 69€ / Tag p.P. (ab 2 Nächte 65€)
            <br/><UnstyledLink href="https://www.hotel-gasthof-post.at">hotel-gasthof-post.at</UnstyledLink>
            <br/>
            <br/>reserviert bis Ende Mai
          </Column>
        </ColumnsContainer>
      </Section>
      <Section id='party' image={image2} position={"right bottom"}>
        <h3>Feier</h3>
        <h4>Dresscode</h4>
        <p>Sommer in Kärnten, was will man mehr  – die Damen können ihre schönsten und luftigsten Kleider ausführen und die Herren sollen auch nicht dahinschmelzen. Ein Dreiteiler muss es daher nicht sein, aber festlich auf alle Fälle.</p>
        <h4>Essen</h4>
        <p>Fisch, Fleisch, Risotto oder doch Pasta? Wir denken, es wird für jeden etwas dabei sein. Falls ihr zusätzliche, notwendige Diätvorgaben habt, gebt uns einfach Bescheid, damit wir diese mit dem Restaurant abklären können.</p>
        <h4>Fotos</h4>
        <p>Da wir eine tolle Fotografin engagiert haben, könnt ihr die Handys während der Trauung gerne eingesteckt lassen und so die Zeremonie in vollen Zügen genießen.</p>
        <p>Nach der Schifffahrt ist ein gemeinsames Gruppenfoto geplant, danach könnt ihr enstpannt die Location erkunden.</p>
        <h4>Geschenke</h4>
        <p>Das schönste Geschenk wird die gemeinsame Feier sein. Falls ihr uns trotzdem etwas schenken wollt, würden wir uns am meisten über eine finanzielle Unterstützung für unser Eigenheim freuen.</p>
      </Section>
      <Section id='contact' image={schifffahrt} position={"bottom"}>
        <h3>Details</h3>
        <h4>RSVP</h4>
        <p>Wir hoffen, dass ihr alle den Termin bereits in euren Kalendern eingetragen habt. Bitte gebt uns bis Ende Juni Bescheid, damit wir die Personenanzahl mit dem Restaurant abstimmen können.</p>
        <h4>Anfragen</h4>
        <p>Falls ihr Fragen, Anregungen oder Ideen habt, nehmt bitte Kontakt mit einem unserer tollen Helfer auf. Franziska, Katharina, Sella und Bernhard können euch bestimmt weiterhelfen.</p>
        <h4>Ausflug</h4>
        <p>Am Samstag nach der Feier hätten wir uns überlegt den Affenberg oder die Adlershow zu besuchen und zur Burgruine Landskron Mittagessen zu gehen. Sollte Interesse bestehen, gebt uns einfach Bescheid. Wir übernehmen gerne die Reservierung.</p>
        <h4>Covid-19</h4>
        <p>Wir hoffen, dass sich die Lage bis September wieder entspannt und wir mit euch diesen schönen Tag feiern können. Um ganz sicher zu gehen und die Feier unbekümmert genießen zu können, würden wir alle Gäste bitten ein aktuelles negatives Corona-Testergebnis mitzunehmen. Wir danken bereits im Voraus für eure Rücksicht und Mithilfe!</p>
        <p>Falls es auf Grund der Situation zu Änderungen des Termins kommt, werden wir euch persönlich informieren.</p>
      </Section>
      <Container>
        <Polaroid image={andiundtoni}/>
      </Container>
      <Arrow />
    </ContentContainer>
  </>
)}