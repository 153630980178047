import React from 'react'
import styled from 'styled-components'

const PolaroidImage = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.image}) no-repeat center;
  background-size: cover;
`
const PolaroidText = styled.div`
  font-family: "AndelliaDavilton";
  font-weight: normal;
  font-size: 3rem;
  margin-top: 10px;
`
const Polaroid = styled.div`
  width: 320px;
  height: 370px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding-bottom: 50px;
  margin-bottom: 50px;
  -webkit-transform: rotate(-2deg);
  -moz-transform: rotate(-2deg);
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.25);
  -moz-box-shadow: 0 3px 6px rgba(0,0,0,.25);
  padding: 15px 13px 15px;

  @media (max-width: 480px) {
    width: 220px;
    height: 270px;
  }
`

const PolaroidComponent = ({image}) => (
    <Polaroid>
        <PolaroidImage image={image}/>
        <PolaroidText>Bis bald :)</PolaroidText>
    </Polaroid>
)

export default PolaroidComponent